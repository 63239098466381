<template>
  <div>
    <div class="no_gnb">
      <slot />
      <Footer />
    </div>
  </div>
</template>

<script setup>
const appHeight = () => {
  const doc = document.documentElement
  doc.style.setProperty('--app-height', `${window.innerHeight}px`)
}

onMounted(() => {
  window.addEventListener('resize', appHeight)
  appHeight()
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', appHeight)
})
</script>

<style lang="scss" scoped>
main {
  position: relative;
  height: 100%;
}
.no_gnb {
  position: relative;
  height: 100vh;
  height: var(--app-height);
  background-color: #000;
}
</style>